header {
    position: relative;
    background-color: $color-third;

    .header {
        transition: .35s;

        > * {
            transition: .35s;
        }

        &__container {
            padding-bottom: 30px;
        }

        &__identity {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(255, 255, 255, .9);
            border-radius: 0px 0px 30px 30px;
            width: 200px;
            height: 170px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 17;
            transition: .35s;

            a, h1, span {
                font-size: 0;
                line-height: 0;
            }

            &__title {
                padding: 10px 0 20px;
            }

            svg {    
                width: 0;
                height: 0;
                opacity: 0;
                transition: .35s;
            }

            img {
                width: 171px;
                height: 138px;
                transition: .35s;
            }
        }
    }
}

.container--header {
    position: relative;
}

.header--sticky {
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
    height: 70px;
    transition: .35s;

    > * {
        transition: .35s;
    }

    .header {
        &__identity {
            width: 100px;
            height: 70px;
            background-color: $color-white;
            border-radius: 0;
    
            svg {
                display: block;
                width: 40px;
                height: 50px;
                opacity: 1;
                transition: .35s;
            }
    
            img {
                width: 0;
                height: 0;
                transition: .35s;
            }

            a, h1, span {
                padding: 0;
            }
        }
    }

    .tools {
        &__accessibility {
            &::after {
                display: none;
            }
        }

        &__fb {
            &::after {
                display: none;
            }
        }

        &__search {
            &::after {
                display: none;
            }

            &--disabled {
                pointer-events: none;
                opacity: .2;
            }
        }
    }
}


//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}

body:not(.home) {
}

.tools {
    position: relative;
    z-index: 16;

    &__content {
        position: relative;
        display: flex;
        justify-content: center;
        background: $color-bg--transparent;
    }

    .tool {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70px;
        margin: 0;
        padding: 0;
        border: 0;
        border-radius: 0;
        
        svg {
            fill: $color-white;
            transition: all $duration;
        }

        &:hover {
            cursor: pointer;

            svg {
                fill: $color-second;
            }
        }
    }

    &__translate {
        svg {
            width: 24px;
            height: 24px;
        }
    }

    &__accessibility {
        position: absolute;
        left: 0;
        width: 50px;
        background-color: $color-bg--tools;
        z-index: 16;

        &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 30px;
            bottom: -30px;
            background-color: $color-bg--tools;
            z-index: -1;
            transition: all .25s ease;
        }

        svg {
            width: 29px;
            height: 26px;
        }

        &:hover {
            background-color: $color-third;
            color: $color-white;
            
            &:after {
                background-color: $color-third;
            }
            
            svg {
                fill: $color-white;
            }
        }
    }

    &__search {
        position: absolute;
        right: 0;
        background-color: $color-main;
        height: 70px;
        border-radius: 0;
        font-weight: normal;
        font-size: 1.25rem;
        line-height: 1.5rem;
        width: 200px;
        z-index: 16;

        &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 30px;
            bottom: -30px;
            background-color: $color-main;
            z-index: -1;
            transition: all .25s ease;
        }

        svg {
            width: 16px;
            height: 16px;
            margin-right: 10px;
        }

        &:hover {
            background-color: $color-third;
            color: $color-white;

            &:after {
                background-color: $color-third;
            }
        }

        &--disabled {
            pointer-events: none;
            opacity: .2;
        }
    }
    
    &__fb {
        position: absolute;
        left: 70px;
        width: 50px;
        background-color: $color-bg--tools;
        z-index: 16;

        &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 30px;
            bottom: -30px;
            background-color: $color-bg--tools;
            z-index: -1;
            transition: all .25s ease;
        }

        svg {
            width: 10px;
            height: 22px;
        }

        &:hover {
            background-color: $color-third;
            color: $color-white;
            
            &:after {
                background-color: $color-third;
            }
            
            svg {
                fill: $color-white;
            }
        }
    }
}

.tools-view {
    position: absolute;
    top: 165px;
    width: 100%;
    z-index: 1;

    .container--tools-view {
        display: flex;
        justify-content: flex-end;

        #google_translate_element {
            display: none;
        }

        .profils {
            position: relative;
            display: none;
            width: 240px;
            height: auto;
            margin-top: 45px;
            margin-right: 60px;
            padding: 15px 20px 20px;
            background: $color-second;

            &.show-list {
                display: flex;
                flex-direction: column;
            }

            &__title {
                color: $color-white;
                text-transform: uppercase;
                margin: 20px 0 15px;
            }

            &__list {
                li {
                    a {
                        position: relative;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        padding: 10px 20px 10px 0px;
                        border-top: 1px solid $color-white;
                        color: $color-white;
                        transition: all $duration;

                        &:hover {
                            padding-left: 10px;
                        }

                        &::after {
                            content: "\f344";
                            font: normal 13px/1 dashicons;
                            display: flex;
                            align-items: center;
                            position: absolute;
                            right: 0;
                            height: 100%;
                            color: $color-white;
                        }
                    }

                    &:last-child {
                        a {
                            border-bottom: 1px solid $color-white;
                        }
                    }
                }
            }
        }
    }
}

iframe.goog-te-menu-frame.skiptranslate {
    position: absolute;
    top: 230px !important;
    left: calc(100% - ((100% - 1200px) / 2) - 335px) !important;
}

//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 99;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute;
        left: calc(100% - (100% - 960px)/2 - 335px)!important;
        top: 230px!important;
    }

    header {
        .header {
            &__identity {
                width: 160px;
                height: 140px;
    
                &__title {
                    padding: 8px 0 15px;
                }
        
                img {
                    width: 137px;
                    height: 111px;
                }
            }
        }
    }
    
    .header--sticky {
        .header {
            &__identity {
                width: 90px;
            }
        }
    }
    
    .tools {
        &__accessibility {
            width: 40px;

            svg {
                width: 21px;
                height: 19px;
            }
        }

        &__search {
            font-size: 1.125rem;
            line-height: 1.375rem;
            width: 160px;

            svg {
                margin-right: 8px;
            }
        }
        
        &__fb {
            width: 40px;
            left: 55px;

            svg {
                width: 8px;
                height: 18px;
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute;
        left: calc(100% - (100% - 640px)/2 - 335px)!important;
        top: 240px!important;
    }

    .privacy {
        &__container {
            flex-wrap: wrap;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }
    
    .tools {
        &__accessibility {
            width: 50px;

            svg {
                width: 29px;
                height: 25px;
            }
        }

        &__search {
            left: 140px;
            right: auto;
            width: 50px;
            font-size: 0;
            line-height: 0;

            svg {
                margin-right: 0;
                width: 22px;
                height: 22px;
            }
        }
        
        &__fb {
            width: 50px;
            left: 70px;

            svg {
                width: 10px;
                height: 22px;
            }
        }
    }
  
}


// 640
@media screen and (max-width: $small) {

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute!important;
        left: calc(100% - (100% - 300px/2))!important;
        top: 280px!important;
    }

    .container--header {
        max-width: 100%;
        padding: 0;
    }

    header {
        .header__identity {
            position: relative;
            width: 100%;
            height: 125px;
            border-radius: 0;
            background-color: $color-white;

            img {
                width: auto;
                height: 107px;
            }
        }

    }

    .tools {
        &__content {
            top: 125px;
        }
    }
}
