#nav-main {
    position: relative;
    padding: 0 210px 0 130px;
    //z-index: 10;

    .container--nav {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
    }

    .menu__nav__list {
        display: flex;
        flex-wrap: wrap;
        transition: $duration ease-out;
    } 

    .nav-main-item {
        z-index: 40;

        &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
            .sub-menu {
                display: flex;
                justify-content: center;
            }
        }

        &:nth-child(2) {
            margin-right: 100px;
            transition: .35s;
        }
        
        &:nth-child(3) {
            margin-left: 100px;
            transition: .35s;
        }

        .nav-main-link {
            position: relative;
            display: flex;
            align-items: center;
            height: 70px;
            padding: 0 25px;
            color: $color-white;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            text-shadow: 0px 0px 20px rgb(0 0 0 / 10%);
            font-size: 1.25rem;
            line-height: 1.5rem;

            &:after {
                content: '';
                position: absolute;
                bottom: -15px;
                left: 0;
                right: 0;
                margin: auto;
                width: 30px;
                height: 30px;
                background-image: url(/wp-content/themes/saint-meen-le-grand/assets/src/images/sprite-images/home/fleche_defilement.svg);
                background-size: 30px 30px;
                transform: rotate(90deg);
                opacity: 0;
                z-index: 9;
                transition: all .25s ease;
            }

            &:hover {
                &::after {
                    opacity: 1;
                }
            }
        }

        .sub-menu {
            display: none;
            position: absolute;
            left: 0;
            width: 100%;
            padding: 30px 15px;
            background: $color-bg--submenu;

            &:before,
            &:after {
                content: "";
                width: 2000px;
                height: 100%;
                top: 0;
                position: absolute;
                background: $color-bg--submenu;
            }

            &:before {
                left: -2000px;
            }

            &:after {
                right: -2000px;
            }

            ul {
                width: 100%;
                column-count: 3;
                column-gap: 30px;
                padding: 0;
            }

            .nav-child-item, .nav-grandchild-item {
                width: 100%;
                display: inline-block;
                padding: 0;

                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    height: auto;
                    padding: 15px 30px 15px 10px;
                    border-bottom: 1px solid $color-main;
                    color: $color-white;
                    font-weight: $font-weight-bold ;
                    border-radius: 5px;

                    &::after {
                        content: '\f345';
                        font: normal 20px/1 dashicons;
                        position: absolute;
                        right: 5px;
                        color: $color-white;
                        transition: all $duration;
                    }

                    &:hover {
                        background: $color-main;
                        
                        &::before {
                            width: 100%;
                        }
                    }
                }
            }

            .nav-grandchild-item {
                a {
                    display: block;
                    padding: 17px 10px 17px 15px;
                    border-bottom: 0;
                    font-size: $font-size--text-small;
                    font-weight: $font-weight;
                    text-transform: initial;

                    &::before {
                        display: none;
                    }
                } 
            }
        }
    }
}

// Sticky menu  
.header--sticky {
    #nav-main {
        .nav-main-item {
            &:nth-child(2) {
                margin-right: 70px;
            }

            &:nth-child(3) {
                margin-left: 70px;
            }
        }
    }
}

// Responsive button
.nav-main_button {
    display: none;
}

// Responsive button label
.nav-main_icon {
    display: none;
    align-items: center;
    cursor: pointer;
    padding: 10px 20px;
    color: $color-white;

    .navicon {
        position: relative;
        display: block;
        height: 4px;
        top: 0px;
        width: 30px;
        margin-right: 10px;
        margin-left: 140px;
        background: $color-white;
        transition: all $duration ease-out;

        &:before, 
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background: $color-white;
            transition: all $duration ease-out;
        }

        &:before {
            top: 9px;
        }

        &:after {
            top: -9px;
        } 
    }
}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {
    #nav-main {
        padding: 0 160px 0 100px;

        .nav-main-item {
    
            &:nth-child(2) {
                margin-right: 90px;
            }
            
            &:nth-child(3) {
                margin-left: 90px;
            }
    
            .nav-main-link {
                padding: 0 13px;
                font-size: 1.125rem;
                line-height: 1.375rem;
            }
        }
    }

    // Sticky menu  
    .header--sticky {
        #nav-main {
            .nav-main-item {
                .nav-main-link {
                    padding: 0 25px;
                }

                &:nth-child(2) {
                    margin-right: 50px;
                }
    
                &:nth-child(3) {
                    margin-left: 50px;
                }
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    #nav-main {
        position: relative;
        padding: 0;

        .container--nav {
            position: relative;
            justify-content: flex-end;
            margin: 0;
        }

        .menu__nav__list {
            display: flex;
            flex-flow: column;
            position: absolute;
            width: 100%;
            top: 70px;
            z-index: 40;
            margin: 0;
            background: $color-bg--submenu ;

            &:before,
            &:after {
                content: "";
                width: 2000px;
                height: 100%;
                top: 0;
                position: absolute;
                background: $color-bg--submenu;
            }

            &:before {
                left: -2000px;
            }

            &:after {
                right: -2000px;
            }
        }

        .nav-main-item {
            &:nth-child(2) {
                margin-right: 0;
            }

            &:nth-child(3) {
                margin-left: 0;
            }

            .sub-menu {
                background: transparent;
                
                &:before,
                &:after {
                    display: none;
                }

                .nav-child-item {
                    a {
                        &:before {
                            left: 0;
                        }
                    }
                }
            }
            .nav-main-link {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                max-width: 100%;
                height: 60px;
                padding: 0 20px;
                border-bottom: 1px solid $color-white;
                color: $color-white;
                text-transform: uppercase;

                &:first-child {
                    padding: 0 20px;
                }

                &:after {
                    display: none;
                }
            }

            &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
                .sub-menu {
                    display: none;
                }
            }

            &.active,
            &.menu-item-has-children.active {

                .nav-main-link {
                    color: $color-third;
                    background: $color-bg--neutral;
                }

                .sub-menu {
                    display: flex;
                    position: relative;
                    padding: 0;
                    box-shadow: none;

                    .nav-drop {
                        column-count: 1;
                        padding: 0;
                    }

                    .nav-grandchild-item {
                        a {
                            padding: 10px;
                        }
                    }

                    li:last-child {
                        a {
                            border-bottom: 0;

                            &::before {
                                display: none;
                            }

                            &::after {
                                top: 50%;
                                transform: translate(0,-50%);
                            }
                        }
                    }
                }
            }

        }

        // Responsive button
        .nav-main_button {
            ~ .menu {
                clear: both;
                transform: scaleY(0);
                transform-origin: 50% 0;
                transition:transform .3s ease;
            }

            &:checked {
                ~ .menu {
                    transform: scaleY(1);
                }
                ~ .nav-main_icon {
                    .navicon {
                        background: $color-bg--transparent;

                        &:before {
                            transform: rotate(-45deg);
                        }

                        &:after {
                            transform: rotate(45deg);
                        }
                    }

                    &:not(.steps) {
                        .navicon {
                            &:before,
                            &:after {
                                top: 0;
                            }
                        }
                    }
                }
            }
        }

        // Responsive button label
        .nav-main_icon {
            display: flex;
            width: 100%;
            height: 70px;
            margin: 0;
            padding: 0;
            font-size:1.875rem;
            line-height: 2.25rem;
            font-weight: $font-weight-bold;
            text-transform: uppercase; 
            width: 50%;
        }
    }
    
}

// 640
@media screen and (max-width: $small) {
    #nav-main {
        .container--nav {
            margin: auto;
        }

        .nav-main_icon {
            font-size: 0;
            line-height: 0;
            margin-right: 45px;
            justify-content: flex-end;

            .navicon {
                margin: 0;
            }
        }
    }
}
