.englobe_wrapper {
    overflow: hidden;
    position: relative;
    background: $color-white;
  }
  .home {
    .content-wrapper {
        &:after {
            bottom: -20px;
        }
    }
  }
.front-page {
    position: relative;

    h2 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 3.375rem;
        color: $color-dark;
        border-bottom: none;
        padding-bottom: 0;
        text-align: center;
        margin-bottom: 30px;
        margin-top: 0;
    }
    .swiper-button-next,
    .swiper-button-prev {
        &:after {
            display: none;
        }
    }
    .swiper-button-next,
    .swiper-button-prev {
        width: 40px;
        height: 40px;
        background-color: $color-main;
        border-radius: 50%;
        transition: .35s;

        svg {
            fill: $color-white;
            transition: .25s;
            width: 11px;
            height: 19px;
        }
        &:hover {
            background-color: $color-third;
        }
    }
}

.container--home {
    display: flex;
    flex-wrap: wrap;
    width: $large;
}



//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;
    z-index: 16;
    margin-top: -30px;

    .swiper-container {
        height: 630px;
        width: 100%;    
        clip-path: ellipse(200% 100% at bottom);

        .swiper-slide {
            img {
                position: absolute;
                object-fit: cover;
                width: 100%;
                height: 100%;
                object-position: top;
            }
        }

        .container--pagination {
            .swiper-button-prev {
                left: calc(((100% - 1200px) / 2) + 15px);
            }

            .swiper-button-next {
                right: calc(((100% - 1200px) / 2) + 15px);
            }
        }
    }

    .slideshow-effect {
        background: linear-gradient(180deg, rgba(50, 50, 50, 0) 30.73%, rgba(50, 50, 50, 0.28) 100%);
        box-shadow: inset 0px 5px 15px rgb(0 0 0 / 30%);
        position: absolute;
        z-index: 5;
        width: 100%;
        height: 100%;
    }

    .slideshow {
        display: inherit;
        position: relative;
        width: 100%;
        height: 100%;

        .container--slideshow {
            position: relative;
            height: 100%;
            z-index: 9;
        }

        &__content {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            position: absolute;
            right: 0;
            left: 0;
            bottom: 150px;
            margin: auto;
            max-width: 900px;
            height: auto;
            padding: 20px;
            color: $color-white;
            text-shadow: 0px 0px 20px $color-black;
        }

        &__title {
            margin-bottom: 15px;
            font-size: 2.125rem;
            line-height: 2.125rem;
            font-weight: $font-weight-bold;
            text-align: center;
        }
    }

    .video-container {
        height: 540px;
        width: 100%;

        .slideshow {
            display: inherit;
            position: relative;
            width: 100%;
            height: 100%;

            video,
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }

            img {
                display: none;
            }
        }
    }
}

//======================================================================================================
// Home Access
//======================================================================================================
.home-access {
    position: relative;
    background-color: transparent;
    z-index: 17;
    margin-top: -110px;


    &__links {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }


    &__link {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 140px;

        &:hover {
            .home-access{
                &__svg-container {
                    background: $color-third;
                    
                    svg path[fill="#323232"] {
                        fill: $color-white;
                    }
                }
                
                &__label {
                    color: $color-text;
                }
            }
        }
    }

    &__svg-container {
        width: 140px;
        height: 140px;
        border-radius: 50%;
        background-color: $color-bg--body;
        box-shadow: $shadow--specific;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.35s;

        svg path {
            transition: all 0.35s;
        }
    }

    &__label {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 10px;
        min-height: 50px;
        text-transform: uppercase;
        font-weight: $font-weight-medium;
        color: $color-text;
    }
}

//======================================================================================================
// Home Events
//======================================================================================================
.home-events {
    position: relative;
    background-color: $color-bg--body;
    padding: 120px 0 20px;
    margin-top: -59px;
    z-index: 16;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 60px;
        -webkit-clip-path: ellipse(1270px 100% at bottom);
        clip-path: ellipse(60% 100% at bottom);
        background-color: $color-bg--body;
        top: -60px;
    }

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 60px;
        -webkit-clip-path: ellipse(60% 100% at top);
        background-color: $color-bg--body;
        bottom: -60px;
    }

    .featured-events {
        &__container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-wrap: wrap;
            height: 430px;
        }
        
        &__template {
            margin-top: 40px;
            display: flex;
            justify-content: center;
        }

        &__button {
            height: 55px;
            margin: 0 15px;
            width: 270px;
        }
    }

    .featured-event {
        border-radius: 5px;
        position: relative;
        overflow: hidden;

        &:hover {
            .featured-event {
                &__dates {
                    background-color: $color-third;
                }

                &__title {
                    color: $color-dark;
                }
            }
        }

        &--big {
            width: 470px;
            height: 430px;
            margin-right: 30px;
            
            .featured-event  {
                &__image-container {
                    width: 100%;
                    height: 100%;
                }

                &__content {
                    position: absolute;
                    bottom: 0;
                    background-color: rgba(50, 50, 50, 0.9);
                    padding: 55px 30px 30px;
                    width: 100%;
                }

                &__dates {
                    top: -45px;
                    height: 90px;
                    min-width: 90px;
                    padding: 0 20px;
                }
            }

            &:hover {
                .featured-event {
                    &__content {
                        background-color: $color-second--rgba;
                    }
                }
            }
        } 

        &--small {
            width: 670px;
            height: 200px;
            display: flex;

            .featured-event  {
                &__image-container {
                    width: 225px;
                    height: 200px;
                }

                &__content {
                    flex: 1;
                    background-color: $color-third;
                    padding: 10px 50px 10px 75px;
                }

                &__dates {
                    left: -45px;
                    flex-direction: column;
                    width: 90px;
                    min-height: 90px;
                    padding: 20px 0;

                    svg {
                        transform: rotate(90deg);
                    }
                }

                &__title {
                    font-weight:$font-weight-medium;
                    font-size: 1.625rem;
                    line-height: 2.125rem;
                }   
            }

            &:hover {
                .featured-event {
                    &__content {
                        background-color: $color-second;
                    }
                }
            }
        }

        &__title {
            color: $color-white;
            margin: 0;
        }

        &__image-container {
            background-color: $color-bg--image;
            background-image: url(/wp-content/themes/noyau/assets/images/icon-events.png);
            background-repeat: no-repeat;
            background-position: center;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            } 
        }

        &__dates {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            align-items: center;
            background-color: $color-main;
            color: $color-white;
            border-radius: 100px;

            svg {
                fill: $color-white;
                width: 9px;
                height: 16px;
                margin: 7px;
            }

            > p {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
            }
        }

        &__date-day {
            font-size: 1.875rem;
            line-height: 1.625rem;
            font-weight: $font-weight-bold;
        }

        &__date-month {
            text-transform: uppercase;
            font-size: 1.125rem;
            line-height: 1.25rem; 
            font-weight: $font-weight-normal;
        }

        &__content {
            position: relative;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            transition: .25s;
        }
    }
}

//======================================================================================================
// Home Posts
//======================================================================================================
.home-posts {
    position: relative;
    background-color: $color-dark;
    padding: 120px 0 110px;

    h2 {
        color: $color-white;
    }
    .featured-posts {
        &__container {
            position: relative;
        }
        &__navigation {
            .swiper-button-next, 
            .swiper-button-prev {
                top: 210px;
            }
        }
        &__buttons {
            display: flex;
            justify-content: center;
            margin-top: 40px;

            .button {
                width: 270px;

                &:hover {
                    color: $color-white;
                }
            }
        }
        .swiper-container {
            width: 1000px;
        }
        .swiper-slide {
            width: 470px;
            padding: 0 15px;
        }
    }
    
    .featured-post {
        &__link {
            width: 470px;
            position: relative;
            display: block;
            overflow: hidden;

            &:hover {
                .featured-post {
                    &__content {
                        margin: -150px auto 0;
                    }
                    
                    &__image-container {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }

        &__image-container {
            position: relative;
            width: 470px;
            height: 420px;
            overflow: hidden;
            background-image: url(/wp-content/themes/noyau/assets/images/icon-events.png);
            background-color: $color-bg--image;
            background-position: center;
            background-repeat: no-repeat;
            margin: auto;
            border-radius: 5px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform: scale(1);
                transition: .45s;
            }
        }

        &__content {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 370px;
            height: 195px;
            margin: -125px auto 0;
            background: $color-second--rgba;
            border-radius: 30px;
            padding: 30px;
            box-sizing: border-box;
            color: $color-dark;
            transition: .25s;
        }

        &__title {
            margin: 0 0 10px;
            font-size: 1.625rem;
            line-height: 1.875rem;
            color: $color-dark;
        }
    }
}


//======================================================================================================
// Home Publications
//======================================================================================================
.home-documents {
    position: relative;
    background-color: $color-second;
    padding: 20px 0 110px;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 60px;
        clip-path: ellipse(60% 100% at bottom);
        background-color: $color-second;
        top: -60px;
    }

    &__container {
        display: flex;
        align-items: center;
        margin-top: 60px;
    }

    &__template-btn {
        margin-top: 75px;
        display: flex;
        justify-content: center;

        .button {
            margin: 0;
            width: 270px;
        }
    }

    .home-document {
        display: flex;
        background-color: $color-third;
        position: relative;
        height: 220px;
        width: 50%;
        align-items: center;

        &--left {
            flex-direction: row-reverse;
            padding-right: 15px;
            border-radius: 30px 0 0 30px;

            .home-document {
                &__container-image {
                    margin-left: 40px;
                }

                &__content {
                    text-align: right;
                    padding-left: 40px;
                }

                &__buttons {
                    justify-content: flex-end;    
                    right: 0;
                }

                &__btn {
                    margin-left: 22px;
                }
            }
        }

        &--right {
            padding-left: 15px;
            border-radius: 0 30px 30px 0;

            .home-document {
                &__content {
                    padding-right: 40px;
                }

                &__container-image {
                    margin-right: 40px;
                }

                &__buttons {
                    left: 0;
                }
                
                &__btn {
                    margin-right: 22px;
                }
            }
        }

        &__container-image {
            width: 200px;
            height: 282px;
            overflow: hidden;
            filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
            border-radius: 5px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 5px;
            }

            &--no-image {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $color-bg--image;
            
                svg {
                  width: 40%;
                }
            }
        }

        &__content {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            color: $color-white;
            box-sizing: border-box;
            height: 100%;
            padding-bottom: 15px;
        }

        &__title {
            color: $color-white;
            font-size: 1.5rem;
            line-height: 1.75rem;
            margin: 0 0 13px;
            width: 290px;
            word-wrap: break-word;
        }

        &__date {
            display: inline-block;
            vertical-align: middle;
        }

        &__filesize {
            display: inline-block;
            vertical-align: middle;

            span {
                text-transform: uppercase;
            }
        }

        &__buttons {
            display: flex;
            position: absolute;
            bottom: -27px;
        }

        &__btn {
            background-color: $color-main;
            width: 57px;
            height: 55px;
            border-radius: 100px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            padding: 15px;

            svg {
                width: 57px;
                height: 55px;
                fill: $color-white;
            }

            &:hover {
                background-color: $color-third
            }
        }
    }
}

//======================================================================================================
// AdminAreas
//======================================================================================================
.admin-area {
    padding: 40px 0 115px;
    margin-bottom: -40px;
    position: relative;
    z-index: 5;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 60px;
        clip-path: ellipse(60% 100% at bottom);
        background-color: $color-bg--body;
        top: -30px;
    }
}
.admin-blocs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
    margin: -20px;

    .bloc {
        display: flex;
        width: 270px;
        height: 200px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        transition: .35s;

        &:hover {
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);

            .bloc {
                &__image {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }

        &:nth-of-type(1) {
            border-radius: 60px 0 0 60px;

            .bloc {
                &__wrapper {
                    border-radius: 60px 0 0 60px;
                }
    
                &__image {
                    border-radius: 60px 0 0 60px;
    
                    img {
                        border-radius: 60px 0 0 60px;
                    }
                }
    
                &__content {
                    border-radius: 60px 0 0 60px;
                }
            }
        }
        &:nth-last-of-type(1) {
            border-radius: 0 60px 60px 0;

            .bloc {
                &__wrapper {
                    border-radius: 0 60px 60px 0;
                }
    
                &__image {
                    border-radius: 0 60px 60px 0;
    
                    img {
                        border-radius: 0 60px 60px 0;
                    }
                }
    
                &__content {
                    border-radius: 0 60px 60px 0;
                }
            }
        }

        &__wrapper {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            height: 100%;
            overflow: hidden;

            &--video {
                cursor: pointer;

                &::before {
                    content: '\f126';
                    font: normal 34px/1 dashicons;
                    position: absolute;
                    z-index: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    color: $color-white;
                }
            }
        }

        &__image {
            position: absolute;
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                transform: scale(1);
                transition: .35s;
            }
        }

        &__content {
            position: relative;
            z-index: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0 25px;
            color: $color-white;
            transition: all 0.5s;
        }

        &__title {
            text-align: center;
            font-size: 1.75rem;
            line-height: 2rem;
            text-shadow: 0px 0px 5px $color-black;
            margin: 0;
            font-weight: $font-weight-bold;
        }

        .popup-area {
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 20;
            width: 100%;
            height: 100%;
            opacity: 0;
            visibility: hidden;
            background: $color-black--rgba;

            &.show-popup {
                visibility: visible;
                opacity: 1;
            }

            .accordion {
                height: 0;
                color: $color-white;

                svg {
                    fill: $color-white;
                }
            }

            .close-area-popup {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                position: absolute;
                top: 40px;
                right: calc((100% - 1170px)/2);
                width: 40px;
                height: 40px;
                background: $color-white;
                border-radius: 50px;

                &::before {
                    content: '\f335';
                    font: normal 30px/1 'dashicons';
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    
    .home {
        .content-wrapper {
            &:after {
                bottom: -20px;
            }
        }
    }

    .home-slideshow {
        .swiper-container {
            height: 500px;

            .container--pagination {
                .swiper-button-prev {
                    left: calc(((100% - 960px) / 2) + 15px);
                }
    
                .swiper-button-next {
                    right: calc(((100% - 960px) / 2) + 15px);
                }
            }
        }
        .slideshow {
            &__content {
                bottom: 90px;
            }
        }
    }

    .home-access {    
        margin-top: -80px;

        &__svg-container {
            width: 120px;
            height: 120px;
        }
    }

    .home-events {
        &:before {
            top: -85px;
        }

        &:after {
            bottom: -40px;
            height: 50px;
        }

        .featured-events {
            &__container {
                height: 350px;
            }
        }
    
        .featured-event {
            &--big {
                width: 380px;
                height: 350px;
                margin-right: 20px;
                
                .featured-event  {
                    &__dates {
                        top: -40px;
                        height: 80px;
                        min-width: 85px;
                    }
                }
            }
    
            &--small {
                width: 540px;
                height: 165px;
    
                .featured-event  {
                    &__image-container {
                        width: 185px;
                        height: 165px;
                    }
    
                    &__content {
                        padding: 10px 20px 10px 60px;
                    }
    
                    &__dates {
                        left: -40px;
                        flex-direction: column;
                        width: 80px;
                        min-height: 80px;
                    }
                }
            }

            &__dates {
                padding: 15px 0;
            }
        }
    }

    .home-posts {
        padding: 90px 0 120px;

        .featured-posts {
            &__navigation {
                .swiper-button-next, 
                .swiper-button-prev {
                    top: 170px;
                }
            }
            .swiper-container {
                width: 800px;
            }
            .swiper-slide {
                width: 380px;
                padding: 0 10px;
            }
        }
        
        .featured-post {
            &__link {
                width: 380px;
            }
    
            &__image-container {
                width: 380px;
                height: 340px;
            }
    
            &__content {
                width: 320px;
                height: 210px;
                margin: -110px auto 0;
            }
    
            &__title {
                font-size: 1.5rem;
                line-height: 1.5rem;
            }
        }
    }

    .home-documents {
        padding: 0px 0 110px;
    
        &__container {
            margin-top: 50px;
        }
    
        &__template-btn {
            margin-top: 60px;
        }
    
        .home-document {
            height: 160px;
    
            &--left {
                padding-right: 10px;
    
                .home-document {
                    &__container-image {
                        margin-left: 30px;
                    }
    
                    &__content {
                        text-align: right;
                        padding-left: 20px;
                    }
    
                    &__btn {
                        margin-left: 20px;
                    }
                }
            }
    
            &--right {
                padding-left: 10px;
    
                .home-document {
                    &__content {
                        padding-right: 30px;
                    }
    
                    &__container-image {
                        margin-right: 20px;
                    }

                    &__btn {
                        margin-right: 20px;
                    }
                }
            }
    
            &__container-image {
                width: 140px;
                height: 200px;
            }
    
            &__content {
                padding-bottom: 5px;
            }
    
            &__title {
                font-size: 1.25rem;
                line-height: 1.5rem;
                width: 270px;
            }
    
            &__buttons {
                bottom: -22px;
            }
    
            &__btn {
                width: 45px;
                height: 45px;
                padding: 10px;
    
                svg {
                    width: 45px;
                    height: 45px;
                }
            }
        }
    }

    .admin-blocs {
        .bloc {
            width: 220px;
            height: 162px;

            &__title {
                font-size: 1.5rem;
            }
        }
    } 
}


// 960
@media screen and (max-width: $medium) {
    .home-slideshow {
        .swiper-container {
            height: 405px;
            
            .container--pagination {
                .swiper-button-prev {
                    left: calc(((100% - 640px) / 2) + 15px);
                }
    
                .swiper-button-next {
                    right: calc(((100% - 640px) / 2) + 15px);
                }
            }
        }
    }

    .home-access {
        &__links {
            flex-wrap: wrap;
        }

        &__link {
            width: 170px;
            margin-bottom: 30px;
        }

        &__svg-container {
            width: 140px;
            height: 140px;
        }
    }

    .home-slideshow {
        position: relative;

        .video-container {
            .slideshow {
                video {
                    display: none;
                }
                img {
                    display: block;
                }
            }
        }
    }

    .home-events {
        padding: 90px 0 20px;

        &:before {
            top: -355px;
        }
        .featured-events {
            &__container {
                height: auto;
            }

            &__template {
                margin-top: 10px;
            }
        }
    
        .featured-event {
            margin-bottom: 30px;

            &--big {
                width: 100%;
                height: 570px;
                margin-right: 0;
                
                .featured-event  {
                    &__dates {
                        min-width: 90px;
                        padding: 0 20px;
                    }
                }
            }
    
            &--small {
                width: 100%;
                height: 200px;
    
                .featured-event  {
                    &__image-container {
                        width: 225px;
                        height: 100%;
                    }
    
                    &__content {
                        padding: 10px 30px 10px 75px;
                    }
    
                    &__dates {
                        left: -45px;
                        flex-direction: column;
                        width: 90px;
                        min-height: 90px;
                        padding: 20px 0;
                    }
                }
            }
        }
    }

    .home-posts {
        padding: 70px 0 100px;
        
        .featured-posts {
            &__navigation {
                .swiper-button-next, 
                .swiper-button-prev {
                    top: 210px;
                }

                .swiper-button-prev {
                    left: 0;
                }
                .swiper-button-next {
                    right: 0;
                }
            }
            .swiper-container {
                width: 490px;
            }
            .swiper-slide {
                width: 470px;
                padding: 0 10px;
            }
        }
        
        .featured-post {
            &__link {
                width: 470px;

                &:hover {
                    .featured-post__content {
                        margin: -125px auto 0;
                    }
                }
            }
    
            &__image-container {
                width: 470px;
                height: 420px;
            }
    
            &__content {
                width: 370px;
                height: 195px;
                margin: -125px auto 0;
            }
    
            &__title {
                font-size: 1.625rem;
                line-height: 1.875rem;
            }
        }
    }

    .home-documents {
        &__container {
            margin-top: 0;
        }
    
        .home-document {
            flex-direction: column;
            height: auto;
            background-color: transparent;
            border-radius: 0;

            &__title {
                width: 100%;
            }
    
            &--left,
            &--right {
                .home-document {
                    &__container-image {
                        margin: auto auto -15px;
                        z-index: 5;
                    }

                    &__content {
                        padding: 30px;
                        text-align: center;
                        width: 100%;
                    } 

                    &__buttons {
                        right: 0;
                        left: 0;
                        justify-content: center;
                    }

                    &__btn {
                        margin: 0 10px;
                    }
                }
            }
    
            &__container-image {
                height: 280px;
                width: 200px;
            }
    
            &__content {
                background-color: $color-third;
                border-radius: 30px;
                height: 160px;
                flex: auto;
            }
        }
    }

    .admin-area {
        padding: 30px 0 95px;
    }
    .admin-blocs {
        .bloc {
            width: 300px;
            height: 218px;
            margin-bottom: 20px;

            &__title {
                font-size: 1.75rem;
            }
            
            &:nth-of-type(1) {
                border-radius: 60px 0 0 0;

                .bloc {
                    &__wrapper {
                        border-radius: 60px 0 0 0;
                    }
        
                    &__image {
                        border-radius: 60px 0 0 0;
        
                        img {
                            border-radius: 60px 0 0 0;
                        }
                    }
        
                    &__content {
                        border-radius: 60px 0 0 0;
                    }
                }
            }

            &:nth-of-type(2) {
                border-radius: 0 60px 0 0;

                .bloc {
                    &__wrapper {
                        border-radius: 0 60px 0 0;
                    }
        
                    &__image {
                        border-radius: 0 60px 0 0;
        
                        img {
                            border-radius: 0 60px 0 0;
                        }
                    }
        
                    &__content {
                        border-radius: 0 60px 0 0;
                    }
                }
            }

            &:nth-of-type(3) {
                border-radius: 0 0 0 60px;

                .bloc {
                    &__wrapper {
                        border-radius: 0 0 0 60px;
                    }
        
                    &__image {
                        border-radius: 0 0 0 60px;
        
                        img {
                            border-radius: 0 0 0 60px;
                        }
                    }
        
                    &__content {
                        border-radius: 0 0 0 60px;
                    }
                }
            }

            &:nth-last-of-type(1) {
                border-radius: 0 0 60px 0;

                .bloc {
                    &__wrapper {
                        border-radius: 0 0 60px 0;
                    }
        
                    &__image {
                        border-radius: 0 0 60px 0;
        
                        img {
                            border-radius: 0 0 60px 0;
                        }
                    }
        
                    &__content {
                        border-radius: 0 0 60px 0;
                    }
                }
            }
        }
    } 
}


// 640
@media screen and (max-width: $small) {
    .home-slideshow {
        .swiper-container {
            height: 310px;
            
            .container--pagination {
                .swiper-button-prev,
                .swiper-button-next {
                    top: 90px;
                    bottom: auto;
                    margin: auto;
                }

                .swiper-button-prev {
                    left: calc(((100% - 320px) / 2) + 15px);
                }
    
                .swiper-button-next {
                    right: calc(((100% - 320px) / 2) + 15px);
                }
            }
        }

        .slideshow {
            &__content {
                bottom: 50px;
            }
        }
    }

    .home-access {
        margin-top: -50px;

        &__link {
            width: 140px;
            margin-bottom: 40px;
        }

        &__svg-container {
            width: 120px;
            height: 120px;
        }
    }
    
    .front-page {
        position: relative;

        h2 {
            font-size: 2.75rem;
        }
        .swiper-button-next,
        .swiper-button-prev {
            width: 35px;
            height: 35px;

            svg {
                width: 10px;
                height: 17px;
            }
        }
    }
    
    .home-events {
        &:before {
            top: -575px;
            height: 40px;
        }

        .featured-events {
            &__template {
                margin-top: 0;
                flex-direction: column;
            }

            &__button {
                margin: 30px 0 0;
            }
        }
    
        .featured-event {
            margin-bottom: 70px;

            &--big {
                width: 100%;
                height: auto;
                
                .featured-event  {
                    &__dates {
                        top: -40px;
                        min-width: 80px;
                    }

                    &__image-container {
                        width: 100%;
                        height: 275px;
                    }
    
                    &__content {
                        position: relative;
                        padding: 55px 30px 30px 30px;
                        background-color: $color-third;
                    }
                }
            }
    
            &--small {
                overflow: inherit;
                height: auto;
    
                .featured-event  {
                    &__image-container {
                        display: none;
                    }
    
                    &__content {
                        padding: 55px 30px 30px 30px;
                    }
    
                    &__dates {
                        left: 30px;
                        top: -40px;
                        flex-direction: row;
                        height: 80px;
                        width: auto;
                        min-height: 0;
                        min-width: 80px;
                        padding: 0 20px;

                        svg {
                            transform: rotate(0);
                        }
                    }
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .home-posts {       
        .featured-posts {
            &__buttons {
                margin-top: 30px;
            }

            &__navigation {
                .swiper-button-next, 
                .swiper-button-prev {
                    top: 90px;
                }
            }
            .swiper-container {
                width: 300px;
            }
            .swiper-slide {
                width: 300px;
                padding: 0;
            }
        }
        
        .featured-post {
            &__link {
                width: 300px;

                &:hover {
                    .featured-post__content {
                        margin: -20px auto 0;
                    }
                }
            }
    
            &__image-container {
                width: 200px;
                height: 180px;
            }
    
            &__content {
                width: 300px;
                height: 205px;
                margin: -20px auto 0;
            }
    
            &__title {
                font-size: 1.5rem;
                line-height: 1.5rem;
            }
        }
    }

    .home-documents {
        &__container {
            flex-direction: column;
        }
    
        .home-document {
            width: 100%;

            &:first-child {
                margin-bottom: 50px;
            }

            &__container-image {
                height: 280px;
                width: 200px;
            }
    
            &__content {
                background-color: $color-third;
                border-radius: 30px;
                height: 160px;
                flex: auto;
            }
        }
    }

    .admin-blocs {
        .bloc {
            width: 300px;
            height: 222px;
            margin-bottom: 30px;
            
            &:nth-of-type(1) {
                border-radius: 60px 60px 0 0;

                .bloc {
                    &__wrapper {
                        border-radius: 60px 60px 0 0;
                    }
        
                    &__image {
                        border-radius: 60px 60px 0 0;
        
                        img {
                            border-radius: 60px 60px 0 0;
                        }
                    }
        
                    &__content {
                        border-radius: 60px 60px 0 0;
                    }
                }
            }

            &:nth-of-type(2),
            &:nth-of-type(3) {
                border-radius: 0;

                .bloc {
                    &__wrapper {
                        border-radius: 0;
                    }
        
                    &__image {
                        border-radius: 0;
        
                        img {
                            border-radius: 0;
                        }
                    }
        
                    &__content {
                        border-radius: 0;
                    }
                }
            }

            &:nth-last-of-type(1) {
                border-radius: 0 0 60px 60px;

                .bloc {
                    &__wrapper {
                        border-radius: 0 0 60px 60px;
                    }
        
                    &__image {
                        border-radius: 0 0 60px 60px;
        
                        img {
                            border-radius: 0 0 60px 60px;
                        }
                    }
        
                    &__content {
                        border-radius: 0 0 60px 60px;
                    }
                }
            }
        }
    } 
}
