@keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.accordion {
    position: relative;
    margin-top: 30px;

    &__title {
        box-sizing: border-box;
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        outline: $color-white;

        &--question {
            min-height: 70px;
            padding: 20px 70px 20px 30px;
            font-family: $font-family--heading;
            font-size: $font-size--4;
            font-weight: $font-weight-bold;
            line-height: $line-height--heading;
            color: $color-white;
            background-color: $color-third;
            border: none;
        }

        &--transcription {
            display: inline-block;
            font-size: $font-size--4;
            line-height: $line-height;
            font-size: $font-size--text;
        }
    }

    &__content {
        margin-top: 0;
        background-color: $color-third;
        z-index: 2;

        &--question {
            padding: 30px;
            background-color: $color-second;
        }

        &--transcription {
            padding: 10px 20px 0 0;
        }
    }

    details {
        animation: appear .5s;

        summary > svg {
            position: absolute;
            z-index: 2;
            margin: auto;
            transform: rotate(0);
            transition: $duration ease;
        }

        &[open] {
            height: auto;

            summary > svg {
                transform: rotate(-180deg);
            }
        }

        summary {
            &::-webkit-details-marker {
                display: none;

            }
        }
    }

    &--question {
        details {
            border-radius: $border-radius;
            overflow: hidden;

            summary > svg {
                fill: $color-white;
                right: 30px;
                top: 15px;
                width: 40px;
                height: 40px;
            }
        }
    }

    &--transcription {
        display: inline-flex;
        margin-top: 10px;
        margin-bottom: 10px;
        background-color: $color-third;
        color: $color-white;

        details {
            summary > svg {
                width: 26px;
                height: 23px;
                fill: $color-white;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .accordion__content--question {
        padding: 20px 20px 20px;
    }
    .accordion__title--question {
        padding: 20px 70px 20px 20px;
    }

}


// 640
@media screen and (max-width: $small) {

    .accordion__title--question {
        font-size: 1.188rem; //19px
    }

}
