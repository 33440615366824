.footer-top {
    background-color: $color-dark;
    overflow: hidden;

    .footer-infos {
        position: relative;
        display: flex;
        color: $color-white;
        padding: 90px 0 50px;
        max-height: 420px;
        flex-flow: column wrap;
        align-content: flex-start;
        justify-content: space-between;

        &__title {
            font-size: 1.25rem;
            font-weight: $font-weight-bold;
            margin-bottom: 10px;
        }

        &__phone {
            display: flex;
            font-weight: $font-weight-bold;
            margin: 10px 0;

            &:hover {
                text-decoration: underline;
                color: inherit;
            }
        }

        &__wrapper-buttons {
            display: flex;
            flex-direction: column;
            order: 2;
        }

        &__btn {
            width: 252px;
            height: 55px;
            font-weight: $font-weight-medium ;
            font-size: 1rem;
            text-transform: uppercase;
            margin-top: 20px;
            margin-bottom: 0;

            svg {
                margin-right: 20px;
                width: 32px;
                height: 32px;

                path {
                    transition: .35s;
                }
            } 

            &:hover {
                background-color: $color-main;
                color: $color-white;

                svg path[fill='#e3102b'] {
                    fill: #fff;
                }
    
            }
        }

        &__wrapper-links {
            display: flex;
            margin-top: 47px;
        }

        &__link {
            margin-right: 30px;
            opacity: 1;

            &:hover {
                opacity: .6;
            }
        }

        &__address {
            padding-right: 115px;
            order: 1;
            width: auto;
        }

        &__hours {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            order: 3;
            z-index: 5;
            height: 100%;

            .footer-infos__text {
                max-width: 310px;
            }
        }

        &__wrapper-image {
            position: absolute;
            top: 0;
            bottom: 0;
            right: -165px;
            margin: auto;
            width: 635px;
            height: 413px;
            z-index: 3;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 100%;
                width: 2000px;
                height: 100%;
                background-color: $color-second;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__link-map {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            bottom: 120px;
            left: 170px;

            span {
                font-weight: bold;
                font-size: 1.25rem;
                text-transform: uppercase;
                color: $color-dark;
            }

            .link-map__desktop {
                display: flex;
                flex-direction: column;
                align-items: center;

                svg {
                    margin-bottom: 10px;
                    transition: .25s;
                }

                &:hover {
                    color: $color-main;

                    svg {
                        margin-bottom: 20px;
                    }
                }
            }

            .link-map__mobile {
                display: none;
            }
        }
    }
}
.footer-bottom {
    .footer-menu {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        min-height: 50px;

        &__link {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 25px;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .footer-top {
        .footer-infos {
            padding: 70px 0 40px;

            &__address {
                padding-right: 50px;
            }

            &__hours {
                .footer-infos__text {
                    max-width: 240px;
                }
            }

            &__wrapper-image {
                top: auto;
                right: -190px;
            }

            &__wrapper-links {
                display: flex;
                margin-top: 25px;
            }

            &__wrapper-buttons {
                position: relative;
                z-index: 4;
            }

            &__link-map {
                width: 320px!important;
                height: 55px!important;
                left: calc(50% + -10px)!important;
            }
        }
    }

    .footer-bottom {
        .footer-menu {
            &__item {
                .footer-menu__link {
                    padding: 10px 15px;
                }

                &:first-child {
                    
                    .footer-menu__link {
                        padding-left: 0;
                    }
                }
        
                &:last-child {
                    
                    .footer-menu__link {
                        padding-right: 0;
                    }
                }
            }
        }
    }
}
// 960
@media screen and (max-width: $medium) {
    .footer-top {
        .footer-infos {
            flex-wrap: wrap;
            max-height: 620px;
            
            &__address {
                width: 50%;
                padding-right: 20px;
            }

            &__hours {
                width: 50%;

                .footer-infos__text {
                    max-width: 100%;
                }
            }

            &__wrapper-image {
                position: relative;
                height: auto;
                width: 100%;
                right: auto;
                order: 4;
                flex: 1;
                left: -50%;

                img {
                    display: none;
                }

                &:after {
                    display: none;
                }
            }

            &__wrapper-buttons {
                width: 50%;
            }

            &__link-map {
                position: relative;
                width: 100%;
                bottom: auto;
                left: 0;
                flex-direction: row;
                background: rgba(255, 255, 255, 0.15);
                border-radius: 100px;
                height: 60px;
                margin-top: 30px;
                justify-content: center;

                &:hover {
                    background-color: $color-second;

                    .link-map__mobile {
                        color: $color-text;
                        
                        svg {
                            path[fill='#fff'] {
                                fill: $color-text;
                            }
                        }
                    }
                }
 
                .link-map__desktop {
                    display: none;
                }

                .link-map__mobile {
                    display: flex;
                    align-items: center;
                    color: #fff;
                    font-weight: 500;
                    font-size: 1rem;

                    svg {
                        margin-right: 20px;

                        path {
                            transition: .35s;
                        }
                    }
                }
            }

            &__wrapper-links {
                margin-top: 45px;
            }
        }
    }
    .footer-bottom {
        .footer-menu {
            &__link {
                padding: 10px;
            }
        }
    }
}
// 640
@media screen and (max-width: $small) {
    .footer-top {
        .footer-infos {
            max-height: 100%;

            &__address,
            &__hours {
                width: 100%;
                text-align: center;
            }
            &__address {
                padding-right: 0;
            }

            &__hours {
                max-width: 100%;
                order: 2;
                margin-top: 40px;
            }

            &__phone {
                justify-content: center;
            }

            &__wrapper-buttons {
                order: 3;
                width: 100%;
            }

            &__btn {
                width: 100%;
            }

            &__wrapper-links {
                justify-content: center;
                margin-bottom: 10px;
            }

            &__link {
                margin: 0 17px;
            }

            &__wrapper-image {
                left: 0;
            }

            &__link-map {
                margin-top: 20px;
                left: auto !important;
                max-width: 300px;

                .link-map__mobile {
                    svg {
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    .footer-bottom {
        .footer-menu {
            margin: 20px 0;

            &__item {
                .footer-menu__link {
                    padding: 10px;
                }

                &:first-child {
                    
                    .footer-menu__link {
                        padding: 0;
                    }
                }
        
                &:last-child {
                    
                    .footer-menu__link {
                        padding: 0;
                    }
                }
            }
        } 
    }
}