.container {
    @extend %container;
}

#sprite-svg {
    @extend %visuallyhidden;
}


// Fix 30/06/2021 for grid col-12
.contextual-publications > .card.col-12 {
    padding: 0 0 15px 0;
  }
