.cover {
    position: relative;
    margin-top: -30px;
    z-index: 16;
    padding-bottom: 15px;

    &:before{
        content: "";
        position: absolute;
        width: 100%;
        height: 60px;
        clip-path: ellipse(60% 100% at bottom);
        background-color: $color-white;
        bottom: 0;
        z-index: 1;
    }

    &__image-wrapper {
        width: 100%;
        height: 560px;
        background: $color-bg--image;
        clip-path: ellipse(160% 100% at bottom);

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        position: relative;
        background: $color-second;
        padding: 17px 0 100px;
        text-align: center;
        position: relative;
        margin-top: 40px;

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 80px;
            -webkit-clip-path: ellipse(60% 100% at top);
            clip-path: ellipse(60% 100% at bottom);
            background: $color-second;
            top: -62px;
            right: 0;
            left: 0;
            z-index: 0;
            margin: auto;
        }
    }

    &__title {
        color: $color-text;
        margin: 0 0 20px;
    }

    &__lead-wrapper {
        p {
            font-weight: $font-weight-semibold;
            font-size: $font-size--text-medium;
        }
    }

    &__intro {
        color: $color-text;
    }
}

section[data-cpt="maps"]{
    .cover {
        padding: 0;

        &:before {
            display: none;
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .cover {
        &__image-wrapper {
            height: 380px;
        }

        &__informations {
            &:before {
                clip-path: ellipse(75% 100% at bottom);
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        &__lead-wrapper {
            p {
                font-weight: $font-weight;
            }
        }
        
        &__image-wrapper {
            height: 255px;
        }

        &__informations {
            &:before {
                clip-path: ellipse(85% 100% at bottom);
                top: -50px;
                height: 70px;
            }
        }
    }

}
