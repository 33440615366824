.breadcrumb {
    position: relative;
    margin: -80px 0 -15px;
    z-index: 5;

    ul  {
        display: flex;
        flex-wrap: wrap;
        min-height: 60px;
        background-color: $color-white;
        width: fit-content;
        margin: auto;
        justify-content: center;
        box-shadow: 0px 0px 10px rgb(0 0 0 / 15%);
        border-radius: 50px;
        padding: 15px 15px;
        box-sizing: border-box;
    }

    li {
        display: flex;
        align-items: center;
        color: $color-text;
        padding: 0 10px;
        font-size: $font-size--text-medium;
        text-align: center;

        a:hover {
            color: $color-main;
        }

        svg {
            fill: $color-main;
            width: 9px;
            height: 14px;
            margin-left: 20px;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        position: relative;
        ul {
            min-height: 40px;
        }

        li {
            min-height: 40px;
        } 
    }

}
